import * as React from "react";
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ContactUs from "../components/contactus";

const linkStyle = {
    textDecoration: "none",
    color: "grey",
    fontWeight: 800
};

const hrStyle = {
    margin: "50px auto",
};

const leftTdStyle = {
    padding: "10px",
};


const IndexPage = () => (
        <Layout>
            <Seo title="Home"/>

            <div align="center">
                <h1 id="services">Services</h1>
                <p>
                    Asteriskを利用したシステムの開発・保守運用・技術支援サービスを提供しています。
                </p>

                <p></p>
                <div className="cardListGrid">
                    <Link
                        to="https://www.navida.ne.jp/snavi/101202_2.html"
                        style={linkStyle}
                        rel="noreferrer noopener"
                    >
                        <div className="card card-skin">
                            <div className="card__imgframe">
                                <StaticImage src="../images/asterisk_operation.jpg" alt={"AsteriskOperation"}/>
                            </div>
                            <div className="card__textbox">
                                <div className="card__titletext">Asterisk開発・保守運用</div>
                                <div className="card__overviewtext">
                                    システム開発、保守運用、不具合調査、設定ファイル解析・アップデートを承ります。
                                </div>
                            </div>
                        </div>
                    </Link>

                    <div className="cardListGrid">
                        <Link
                            to="https://ringing.theshop.jp/"
                            style={linkStyle}
                            rel="noreferrer noopener"
                        >
                            <div className="card card-skin">
                                <div className="card__imgframe">
                                    <StaticImage src="../images/shop-logo.png" alt={"Shop-logo"}/>
                                </div>
                                <div className="card__textbox">
                                    <div className="card__titletext">Ringing オンラインショップ</div>
                                    <div className="card__overviewtext">
                                        IP電話機・ゲートウェイなどのVoIP機器を販売し、
                                        お客様が機器の動作を確認できるところまでサポートいたします。
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="cardListGrid">
                        <Link
                            to="https://www.navida.ne.jp/snavi/101202_1.html"
                            style={linkStyle}
                            rel="noreferrer noopener"
                        >
                            <div className="card card-skin">
                                <div className="card__imgframe">
                                    <StaticImage src="../images/products/hamaphone/PBX_logo_1.png" alt={"Shop-logo"}/>
                                </div>
                                <div className="card__textbox">
                                    <div className="card__titletext">PBXリプレース</div>
                                    <div className="card__overviewtext">
                                        カスタマイズで
                                        あなたの仕事にジャストフィットなPBXを実現。
                                        OEM開発商品として販売もできます。
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <p></p>
                <hr style={hrStyle}/>


                <h1 id="products">Products</h1>

                <p>

                </p>
                <div className="productsCardListGrid">
                    <Link to="/mrfax" style={linkStyle} rel="noreferrer noopener">
                        <div className="card card-long-skin">
                            <div className="card__imgframe">
                                <StaticImage
                                    src="../images/products/fax/FAX_ap3.png"
                                    alt={"FAX送る君"}
                                />
                            </div>
                            <div className="card__textbox">
                                <div className="card__titletext">FAX送る君</div>
                                <div className="card__overviewtext">
                                    コンビニで送るより安い！シンプルなFAX送信アプリ。ファイルを送るだけでなく、写真からFAX原稿を読み取れたり、送付状かんたん作成機能も付いてます。
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to="/siptrunk" style={linkStyle} rel="noreferrer noopener">
                        <div className="card card-long-skin">
                            <div className="card__imgframe">
                                <StaticImage
                                    src="../images/products/sip_trunk/top_cloud_server_0922.2.3.jpg"
                                    alt={"SIPトランク"}
                                    width={900}/>
                            </div>
                            <div className="card__textbox">
                                <div className="card__titletext">SIPトランク</div>
                                <div className="card__overviewtext">
                                    お得な電話回線と通話料金プランをご用意した、SIP電話回線を提供いたします!<br/>
                                    IVRなど機能もついています!
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to="/callintelligence" style={linkStyle} rel="noreferrer noopener">
                        <div className="card card-long-skin">
                            <div className="card__imgframe">
                                <StaticImage
                                    src="../images/products/callintelligence/callintelligence.png"
                                    alt={"コール・インテリジェンス"}
                                    width={900}/>
                            </div>
                            <div className="card__textbox">
                                <div className="card__titletext">コール・インテリジェンス</div>
                                <div className="card__overviewtext">
                                    広告媒体ごとに電話でのお問い合わせの<br/>
                                    効果を可視化することができます！
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to="/freevoice" style={linkStyle} rel="noreferrer noopener">
                        <div className="card card-long-skin">
                            <div className="card__imgframe">
                                <StaticImage
                                    src="../images/products/freevoice/freevoice.png"
                                    alt={"フリーボイス"}
                                    width={900}/>
                            </div>
                            <div className="card__textbox">
                                <div className="card__titletext">フリーボイス</div>
                                <div className="card__overviewtext">
                                    24時間一律料金<br/>
                                    フリーダイヤルより通話料金が安い
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <hr style={hrStyle}/>
                <h1 id="about">About us</h1>
                <h2>Company Profile</h2>
                <table>
                    <colgroup>
                        <col width="30%"/>
                        <col width="70%"/>
                    </colgroup>
                    <tr>
                        <td style={leftTdStyle}>社名</td>
                        <td>株式会社Ringing (英文名 Ringing Inc.)</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>所在地</td>
                        <td>
                            ■本社<br/>
                            〒222-0033 神奈川県横浜市港北区新横浜3丁目7-7 新横浜アリーナ通りビル3F
                            <br/>
                            ( Shinyokohama Arena Street Building 3rd floor, 3-7-7 Shinyokohama, Kohoku-ku,
                            Yokohama-shi, Kanagawa Prefecture )<br/>
                            <Link
                                to={"https://maps.app.goo.gl/gWgKh4FTsG9S528x7"}
                                style={linkStyle}
                                rel="noreferrer noopener"
                                target="_blank"
                            >
                                &lt;Google Maps&gt;
                            </Link><br/>
                            横浜市営地下鉄ブルーライン「新横浜駅」8番出口徒歩1 分<br/>
                            相鉄新横浜線・東急新横浜線「新横浜駅」8番出口徒歩1 分<br/>
                            JR横浜線・東海道新幹線「新横浜駅」北口徒歩3 分<br/>
                        </td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}></td>
                        <td>
                            ■平塚オフィス ※書類・物品はこちらへ送付ください<br/>
                            〒254-0043 神奈川県平塚市紅谷町８－１６ サニープラザ平塚３Ｆ
                            <br/>
                            ( Sunny Plaza Hiratsuka 3rd floor, 8-16 Beniya-cho,
                            Hiratsuka-shi, Kanagawa Prefecture )<br/>
                            <Link
                                to={"https://maps.app.goo.gl/AuUMqsSxL5yjLhy27"}
                                style={linkStyle}
                                rel="noreferrer noopener"
                                target="_blank"
                            >
                                &lt;Google Maps&gt;
                            </Link><br/>
                            JR横浜線・東海道新幹線「平塚駅」西口徒歩1分<br/>
                        </td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>電話番号</td>
                        <td>050-5526-4164</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>代表取締役</td>
                        <td>小川 貴之</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>設立</td>
                        <td>2021年(令和3年) ７月28日</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>資本金</td>
                        <td>1000千円</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>事業内容</td>
                        <td>電気通信事業に係わるシステムの開発、販売、賃貸及び保守の受託</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>許認可番号</td>
                        <td>電気通信事業 A-02-18112<br/>
                            古物商許可 神奈川県公安委員会 第451450020225号
                        </td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>適格請求書発行事業者登録番号</td>
                        <td>T7020001142444</td>
                    </tr>
                </table>
                <p></p>
                <h2>History</h2>
                <table>
                    <colgroup>
                        <col width="30%"/>
                        <col width="70%"/>
                    </colgroup>
                    <tr>
                        <td style={leftTdStyle}>2021年7月28日</td>
                        <td>株式会社Ringing 設立</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>2021年10月01日</td>
                        <td>横浜市港北区新横浜から横浜市中区常磐町へ移転</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>2022年2月22日</td>
                        <td>横浜市中区常磐町から横浜市中区本町へ移転</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>2024年2月23日</td>
                        <td>横浜市中区本町から横浜市港北区新横浜へ移転</td>
                    </tr>
                </table>

                <hr style={hrStyle}/>

                <h1 id="contactus">Contact us</h1>
                <ContactUs/>
            </div>
        </Layout>
    )
;

export default IndexPage;
