import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactUs from "../components/contactus";


const linkStyle = {
    textDecoration: "none",
    color: "grey",
    fontWeight: 800,
}

const hrStyle = {
    margin: "50px auto"
}

const leftTdStyle = {
    padding: "10px",
}

const priceLeftTdStyle = {
    padding: "10px",
}


const IndexPage = () => (
    <Layout>
        <Seo title="Privacy Policy"/>

        {/*<div align="center">*/}
        <div>
            <hr style={hrStyle}/>

            <h1 id="PrivacyPolicy">個人情報保護方針</h1>

            <div>当社は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。
            </div>

            <hr style={hrStyle}/>

            <div>
                <h4>個人情報の管理</h4>
                <p>
                    当社は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。<br/>
                </p>
                <h4>個人情報の利用目的</h4>
                <p>
                    本ウェブサイトでは、お客様からのお問い合わせ時に、お名前、e-mailアドレス、電話番号等の個人情報をご登録いただく場合がございますが、これらの個人情報はご提供いただく際の目的以外では利用いたしません。<br/>
                    お客さまからお預かりした個人情報は、当社からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。<br/>
                </p>
                <h4>個人情報の第三者への開示・提供の禁止</h4>
                <p>
                    当社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。<br/>
                    ・お客さまの同意がある場合<br/>
                    ・お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合<br/>
                    ・法令に基づき開示することが必要である場合<br/>
                </p>
                <h4>個人情報の安全対策</h4>
                <p>
                    当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じます。<br/>
                </p>
                <h4>ご本人の照会</h4>
                <p>
                    お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。<br/>
                </p>
                <h4>法令、規範の遵守と見直し</h4>
                <p>
                    当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。<br/>
                </p>
                <h4>制定</h4>
                <p>
                    ２０２１年１２月１０日<br/>
                    株式会社Ringing<br/>
                    代表取締役　小川　貴之<br/>
                </p>
                <h4>お問い合せ</h4>
                <p>
                    当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。<br/>
                    <br/>
                    株式会社Ringing<br/>
                    〒222-0033 神奈川県横浜市港北区新横浜3丁目7-7 新横浜アリーナ通りビル3F<br/>
                    050-5526-4164<br/>
                </p>
            </div>
            <hr style={hrStyle}/>
            <div align="center">
                <ContactUs/>
            </div>
        </div>
    </Layout>
)

export default IndexPage
