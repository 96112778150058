import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactUs from "../components/contactus";

import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';

import 'react-tabs/style/react-tabs.css';

const linkStyle = {
    textDecoration: "none",
    color: "grey",
    fontWeight: 800,
}

const hrStyle = {
    margin: "50px auto"
}

const leftTdStyle = {
    padding: "10px",
}

const priceLeftTdStyle = {
    padding: "10px",
}


const IndexPage = () => (
    <Layout>
        <Seo title="Asterisk保守運用"/>

        <div align="center">
            <hr style={hrStyle}/>

            <h1 id="asterisk_operation">Topics</h1>

            <Tabs>
                <TabList>
                    <Tab>2023</Tab>
                    <Tab>2022</Tab>
                    <Tab>2021</Tab>
                </TabList>

                <TabPanel>
                    <Link to="/topics/20231220" style={linkStyle} rel="noreferrer noopener">
                        <p>2023.12.20 : この度、「Ringingオンラインショップ」を開設しました</p>
                    </Link>
                    <Link to="/topics/20230328" style={linkStyle} rel="noreferrer noopener">
                        <p>2023.03.28 : 適格請求書発行事業者登録番号のお知らせ</p>
                    </Link>
                </TabPanel>
                <TabPanel>
                    <Link to="/topics/20220517" style={linkStyle} rel="noreferrer noopener">
                        <p>2022.05.17 :かながわ経済新聞に弊社取材記事が掲載されました！</p>
                    </Link>
                    <Link to="/topics/20220302" style={linkStyle} rel="noreferrer noopener">
                        <p>2022.03.02 : *復旧しました* [不具合] FAX送る君にて一部の端末機種でFAXが送信されない不具合が発生しています</p>
                    </Link>
                    <Link to="/topics/20220111" style={linkStyle} rel="noreferrer noopener">
                        <p>2022.01.11 : 'Asterisk保守運用'をサービスメニューに追加しました</p>
                    </Link>
                </TabPanel>
                <TabPanel>
                    <Link to="/topics/20211215" style={linkStyle} rel="noreferrer noopener">
                        <p>2021.12.15 : iPhoneアプリ FAX送る君をリリースしました！</p>
                    </Link>
                    <Link to="/topics/20211110" style={linkStyle} rel="noreferrer noopener">
                        <p>2021.11.10 : リクナビNext、Indeedにて採用情報を掲載しています</p>
                    </Link>
                </TabPanel>
            </Tabs>

            <hr style={hrStyle}/>

            <ContactUs/>
        </div>
    </Layout>
)

export default IndexPage
